<template>
  <div>
    <PagePreLoader :loading="dataLoading" />

    <div
      v-if="!dataLoading"
      id="page-data"
    >
      <v-system-bar
        class="mt-12 mt-md-5 invoice-page-system-bar d-print-none"
        color="transparent"
        height="30"
      >
        <div class="d-flex justify-space-between align-center flex-wrap w-100 gap-5">
          <v-btn
            class="d-print-none"
            :to="{ name : routeMap.user.order.name }"
            color="grey"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back to orders
          </v-btn>
          <div class="d-flex align-center btn-wrapper">
            <v-btn
              class="mr-3 d-print-none"
              color="primary"
              outlined
              @click="showReturnForm = !showReturnForm"
            >
              Issue with order ?
            </v-btn>
            <v-btn
              class="d-print-none"
              color="info"
              @click="print(invoice.order_number)"
            >
              Print Invoice
            </v-btn>
          </div>
        </div>
      </v-system-bar>

      <div class="py-3">
        <div
          v-if="invoice"
          id="invoiceHolder"
        >
          <div id="invoice">
            <div id="invoice-mid">
              <div class="inv-info">
                <h2>{{ marketPlaceInfo.name }}</h2>
                <p>
                  Address: {{ marketPlaceInfo.address }} <br>
                  Phone: {{ marketPlaceInfo.phone }} <br>
                  Email: {{ marketPlaceInfo.email }}
                </p>
                <h2 class="mt-1 text-capitalize">
                  {{ customer.name }}
                </h2>
                <p>
                  Email: {{ customer.email }} <br>
                  Phone: {{ customer.phone }}<br>
                  <b>Status:</b>
                  <span
                    class="order-status"
                    v-html="statusBadge(getStatus(invoice, 'order'))"
                  /> <br>
                </p>
              </div>

              <div id="project">
                <div>
                  <time class="text-body-2">
                    {{ invoice.created_at | date }}
                  </time>
                </div>
                <div class="mt-3">
                  <h2>Delivery Details</h2>
                  <p>
                    <b>Order ID:</b> {{ invoice.order_number }}<br><br>
                    House no: {{ customer.house_no }},
                    Road no: {{ customer.road }}, <br>
                    City: {{ customer.city }},
                    County: {{ customer.county }}
                    Postcode: {{ customer.post_code }} <br>
                    <b>Payment Status:</b>
                    <span
                      v-if="invoice.payment_method === 'cash'"
                      class="payment-status"
                      v-html="statusBadge(getStatus(invoice, 'payment'))"
                    />
                    <span
                      v-if="invoice.payment_method === 'card'"
                      class="payment-status"
                      v-html="statusBadge(invoice.payment_status)"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div id="invoice-bot">
              <div id="table">
                <v-simple-table style="width: 100%">
                  <tr class="table-title">
                    <td class="item">
                      <h2>Product</h2>
                    </td>
                    <td class="Hours">
                      <h2>Quantity</h2>
                    </td>
                    <td class="Rate">
                      <h2>Price</h2>
                    </td>
                    <td class="Hours">
                      <h2>Status</h2>
                    </td>
                    <td class="subtotal text-right pr-2">
                      <h2>Sub-total</h2>
                    </td>
                  </tr>

                  <tr
                    v-for="(product, index) in products"
                    :key="'product'+index"
                    class="service"
                  >
                    <td
                      class="table-item"
                      style="max-width: 10%"
                    >
                      <p class="item-text">
                        {{ product.main_product.name }}, {{ product.name }}
                      </p>
                      <p class="product-attribute">
                        <span
                          v-for="(attribute, index) in getFormattedAttributes(product.options)"
                          :key="'attr'+ index"
                        >
                          <b>{{ attribute.name }}:</b> {{ attribute.value }}
                        </span>
                      </p>
                    </td>
                    <td class="table-item">
                      <p class="item-text">
                        {{ product.qty }}
                      </p>
                    </td>
                    <td class="table-item">
                      <p class="item-text">
                        {{ product.price | currency }}
                      </p>
                    </td>
                    <td class="table-item">
                      <p class="item-text text-uppercase">
                        {{ product.status }}
                      </p>
                    </td>
                    <td class="table-item text-right pr-2">
                      <p class="item-text">
                        {{ product.total | currency }}
                      </p>
                    </td>
                  </tr>

                  <tr class="service price-info">
                    <td
                      class="Rate text-right"
                      colspan="3"
                    >
                      <h2>Sub Total</h2>
                    </td>
                    <td
                      class="payment text-right pr-2"
                      colspan="3"
                    >
                      <h2>{{ price.subtotal | currency }}</h2>
                    </td>
                  </tr>
                  <tr class="service price-info">
                    <td
                      class="Rate text-right"
                      colspan="3"
                    >
                      <h2>Delivery Charge</h2>
                    </td>
                    <td
                      class="payment text-right pr-2"
                      colspan="3"
                    >
                      <h2>{{ price.delivery_charge | currency }}</h2>
                    </td>
                  </tr>
                  <tr class="service price-info">
                    <td
                      class="Rate text-right pr-2"
                      colspan="3"
                    >
                      <h2> Discount </h2>
                    </td>
                    <td
                      class="payment text-right pr-2"
                      colspan="3"
                    >
                      <h2>{{ price.discount | currency }}</h2>
                    </td>
                  </tr>
                  <tr class="table-title price-info">
                    <td
                      class="Rate text-right pr-2"
                      colspan="3"
                    >
                      <h2>Total</h2>
                    </td>
                    <td
                      class="payment text-right pr-2"
                      colspan="3"
                    >
                      <h2>{{ price.total | currency }}</h2>
                    </td>
                  </tr>
                </v-simple-table>
                <!--                    Disabled for now, will implement later   -->
                <!--                <v-btn-->
                <!--                  :outlined="showTimeline"-->
                <!--                  class="text-center mt-5 mb-0"-->
                <!--                  color="info"-->
                <!--                  @click="showTimeline = !showTimeline"-->
                <!--                >-->
                <!--                  {{ showTimeline ? 'Hide Order Status' : 'View Order Status' }}-->
                <!--                </v-btn>-->
              </div>


              <!--              <div id="legal-copy">-->
              <!--                <p class="legal">-->
              <!--                  <strong>Thank you for your business!</strong> Payment is expected within 31 days;-->
              <!--                  please-->
              <!--                  process this invoice within that time. There will be a 5% interest charge per month on late invoices.-->
              <!--                </p>-->
              <!--              </div>-->
            </div>

            <!--    Timeline      -->
            <div
              v-if="showTimeline"
              class="timeline-container my-3"
            >
              <v-fade-transition>
                <v-sheet>
                  <v-timeline
                    dense
                  >
                    <v-timeline-item
                      v-for="(timeline, index) in timelines"
                      :key="'timeline'+ index"
                      :small="index === (timelines.length - 1)"
                      color="primary"
                    >
                      <v-row
                        align="center"
                        align-content="center"
                        class="pt-1"
                      >
                        <v-col cols="3">
                          <strong>{{ timeline.created_at | date }}</strong>
                        </v-col>
                        <v-col>
                          <strong v-html="statusBadge(timeline.status)" />
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-sheet>
              </v-fade-transition>
            </div>
          </div>
        </div>
        <div v-else>
          No Invoice found
        </div>
      </div>
    </div>

    <!--    <v-dialog-->
    <!--      v-model="showReturnForm"-->
    <!--      max-width="600"-->
    <!--      persistent-->
    <!--      scrollable-->
    <!--    >-->
    <!--      <v-sheet>-->
    <OrderReturnForm
      v-model="showReturnForm"
      :order="invoice"
      :products="products"
      @close="showReturnForm = !showReturnForm"
    />
    <!--      </v-sheet>-->
    <!--    </v-dialog>-->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { collect } from 'collect.js';
import { userOrders } from '@/api/user';
import { print } from '@/utils/helper';
import routeMap from '@/utils/routeMap';

import OrderReturnForm from '@/components/User/OrderReturn/OrderReturnForm';
import PagePreLoader from '@/components/Utils/PagePreLoader';
import './invoice.scss';
import Toast from '@/utils/Toast';
import { marketPlaceInfo } from '@/utils/constants';
// import Vue from 'vue';
// import VueHtmlToPaper from 'vue-html-to-paper';
// Vue.use(VueHtmlToPaper);



export default {
  name: 'Invoice',
  components: { PagePreLoader, OrderReturnForm },
  data: () => ({
    routeMap,
    dataLoading: false,
    orders: [],
    invoice: {},
    showTimeline: false,
    showReturnForm: false,
    print,
    marketPlaceInfo,
  }),
  computed: {
    timelines() {
      return this.invoice?.timeline;
    },
    customer() {
      return {
        name: this.invoice?.customer?.name,
        email: this.invoice?.customer?.email,
        phone: this.invoice?.customer?.phone,
        house_no: this.invoice?.customer?.house_no,
        road: this.invoice?.customer?.road,
        city: this.invoice?.customer?.city,
        county: this.invoice?.customer?.county,
        post_code: this.invoice?.customer?.post_code,
      };
    },
    products() {
      return this.invoice?.products;
    },
    price() {
      return {
        subtotal: this.invoice?.subtotal,
        delivery_charge: this.invoice?.delivery_charge,
        discount: this.invoice?.discount,
        total: this.invoice?.total,
      };
    },
  },

  // mounted() {
  //   this.dataLoading = true;
  //
  // },

  async beforeMount() {
    await this.getInvoice();
  },

  methods: {

    ...mapActions({
      orderData: 'user/userOrders'
    }),

    async getInvoice() {
      try {
        this.dataLoading = true;
        let invoiceID = Number(this.$route.params.invoice);
        let { data } = await userOrders();
        await this.orderData(data.data);
        this.orders = data.data;
        let orderList = collect(this.orders);
        this.invoice = orderList.where('id', invoiceID).first();
      } catch (e) {
        Toast.error(e.message || 'Could not get order data. Try again.');
      } finally {
        this.dataLoading = false;
      }
    },

    getFormattedAttributes(data) {
      if (!data) {
        return;
      }
      let attributes = JSON.parse(data.toString());
      return attributes.map(item => ({
        name: item.name,
        value: item.value,
      }));
    },

    getStatus(invoice, statusType) {
      if (!invoice.products.length) {
        return;
      }
      let allDelivered = invoice.products.every((product) => product.status === 'delivered');

      if (statusType === 'payment') {
        return allDelivered ? 'paid' : 'unpaid';
      }

      if (statusType === 'order') return invoice.status;
    },

    getTotalAttributePrice(items) {
      let attributes = JSON.parse(items.toString());
      let result = 0;

      if (attributes.length) {
        attributes.map(item => {
          return result += item.price;
        });
      }

      return result;
    },
  },


};
</script>
