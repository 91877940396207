<template>
  <v-row justify="center">
    <v-dialog
      v-model="state.showModal"
      scrollable
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>Issue with order</v-card-title>
        <v-divider />
        <v-card-text
          v-if="state.showModal"
          style="max-height: 600px;"
        >
          <v-form
            v-model="state.validator.isValid"
            class="w-100"
          >
            <v-row class="pt-4">
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-select
                  v-model="state.orderReturnForm.subject"
                  :error-messages="state.errors.subject"
                  :items="state.orderReturnSubject"
                  :rules="state.validator.subject"
                  class="ma-0 pa-0"
                  label="Subject"
                  outlined
                  placeholder="Subject"
                />
              </v-col>

              <!--              <v-col-->
              <!--                v-if="state.showFreeRightSubjectForm"-->
              <!--                cols="12"-->
              <!--                class="pb-0 pt-0"-->
              <!--              >-->
              <!--                <v-text-field-->
              <!--                  v-model="state.orderReturnForm.freeRightSubject"-->
              <!--                  label="Subject"-->
              <!--                  placeholder="Subject"-->
              <!--                  class="ma-0 pa-0"-->
              <!--                  :rules="state.validator.freeRightSubject"-->
              <!--                  :error-messages="state.errors.subject"-->
              <!--                  outlined-->
              <!--                />-->
              <!--              </v-col>-->
              <v-col
                v-if="state.isReturningProduct"
                cols="12"
                class="pb-0 pt-0"
              >
                <v-select
                  v-model="state.orderReturnForm.selectedProductToReturn.item"
                  :items="state.productList"
                  item-text="name"
                  item-value="id"
                  outlined
                  placeholder="Select Product"
                  label="Select Product"
                  :rules="state.validator.returnItem"
                  style="width: 100%"
                />
                <v-text-field
                  v-if="state.selectedItemQuantity"
                  v-model="state.orderReturnForm.selectedProductToReturn.quantity"
                  label="Quantity"
                  class="pa-0 pb-4 plus-minus-icon"
                  append-icon="mdi-plus"
                  hide-details
                  prepend-inner-icon="mdi-minus"
                  type="number"
                  outlined
                  style="width: 100%"
                  @click:append="updateReturnItemQty(true)"
                  @click:prepend-inner="updateReturnItemQty(false)"
                  @change="updateReturnItemQty(false, true)"
                />

                <v-select
                  v-model="state.orderReturnForm.selectedProductToReturn.type"
                  :error-messages="state.errors.subject"
                  :items="state.productReturnType"
                  :rules="state.validator.productRefundType"
                  class="ma-0 pa-0"
                  label="Return type"
                  outlined
                  placeholder="Return type"
                  style="width: 100%"
                />
              </v-col>

              <v-col
                cols="12"
                class="pt-0 pb-0"
              >
                <v-textarea
                  v-model="state.orderReturnForm.description"
                  :rules="state.validator.description"
                  :error-messages="state.errors.description"
                  class="ma-0 pa-0"
                  outlined
                  label="Description"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="d-flex justify-end w-100">
            <v-btn
              color="primary darken-1"
              class="mr-3"
              outlined
              @click="handleModalClose"
            >
              Close
            </v-btn>
            <v-btn
              color="primary darken-1 white--text"
              :loading="state.orderReturnForm.isSubmitting"
              :disabled="!state.validator.isValid"
              @click="handleDataSubmit"
            >
              {{ state.submitButtonText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Toast from '@/utils/Toast';
import collect from 'collect.js';
import { computed, defineComponent, reactive, watch } from 'vue';
import { submitOrderIssue } from '@/api/user';
import ErrorHelper from '@/utils/ErrorHelper';

export default defineComponent({
  name: 'OrderReturnForm',

  extends: true,

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {

    value: {
      type: Boolean,
      default: false
    },

    products: {
      type: [Array, Object],
      default: () => [] || {}
    },

    order: {
      type: Object,
      default: () => {}
    },

    confirmCv: {
      type: Function,
      required: false,
      default: () => {}
    }
  },

  setup(props, { emit }) {

    const state = reactive({

      showModal: props.value,
      orderReturnSubject: [
        {
          text: 'Did not receive item',
          value: 'did-not-receive-item'
        },

        {
          text: 'Missing item or parts of order',
          value: 'missing-item-or-parts-of-order'
        },

        {
          text: 'Damaged or faulty item',
          value: 'damaged-or-faulty-item'
        },
        {
          text: 'Different item to original order',
          value: 'different-item-to-original-order'
        },

        // {
        //   text: 'Cancel Order',
        //   value: 'cancel-order'
        // },

        {
          text: 'Other',
          value: 'other'
        },
      ],
      productReturnType: [
        {
          text: 'Refund',
          value: 'refund'
        },
        {
          text: 'Change Product',
          value: 'exchange'
        }
      ],
      productList: [],

      showFreeRightSubjectForm: computed(() => state.orderReturnForm.subject === 'other'),

      submitButtonText: computed(() => {

        const submitButtonText = {
          submit: 'Submit',
          cancelOrderAndSubmit: 'Cancel order & Submit',
          returnOrderAndSubmit: 'Return item & Submit'
        };

        if (state.orderReturnForm.subject === 'return-order') {
          return submitButtonText.returnOrderAndSubmit;
        }

        if (state.orderReturnForm.subject === 'cancel-order') {
          return submitButtonText.cancelOrderAndSubmit;
        }

        return submitButtonText.submit;

      }),

      isReturningProduct: computed(() => state.orderReturnForm.subject === 'return-item'),
      selectedItemQuantity: computed(() => {

        if (!state.orderReturnForm.selectedProductToReturn.item) return 0;

        const currentItem = collect(props.products)
          .where('id', state.orderReturnForm.selectedProductToReturn.item)
          .first();

        return currentItem.qty;
      }),

      errors: {
        subject: '',
        freeRightSubject: '',
        description: ''
      },


      orderReturnForm: {
        isSubmitting: false,
        subject: '',
        freeRightSubject: '',
        description: '',
        selectedProductToReturn: {
          item: null,
          quantity: 1,
          type: ''
        },
      },

      validator: {

        isValid: false,

        subject: [
          (v) => !!v || 'Subject is required'
        ],

        freeRightSubject: [
          (v) => {
            if (!state.showFreeRightSubjectForm) return true;

            return !!v || 'Subject is required';
          }
        ],

        productRefundType: [
          (v) => {

            if (!state.isReturningProduct) return true;

            if (!v) return 'Select return type';

            const allowedReturnType = ['refund', 'exchange'];
            if (allowedReturnType.includes(v)) return true;

            return 'Invalid Return type';
          }
        ],

        returnItem: [
          (v) => {

            if (!state.isReturningProduct) return true;

            return !!v || 'Select product to return';
          }
        ],

        description: [
          (v) => !!v || 'Description is required'
        ],
      },
    });

    const resetForm = () => {
      state.errors.subject = '';
      state.errors.description = '';
      state.errors.freeRightSubject = '';

      state.orderReturnForm.freeRightSubject = '';
      state.orderReturnForm.subject = '';
      state.orderReturnForm.description = '';
      state.orderReturnForm.selectedProductToReturn.quantity = 1;
      state.orderReturnForm.selectedProductToReturn.item = null;
      state.orderReturnForm.selectedProductToReturn.type = '';

      state.orderReturnForm.isSubmitting = false;
    };


    const updateReturnItemQty = (increase = false, inserting = false) => {

      const currentItemQuantity = state.selectedItemQuantity;

      if (inserting) {

        const updatedValue = state.orderReturnForm.selectedProductToReturn.quantity;

        if (updatedValue < 1) {
          state.orderReturnForm.selectedProductToReturn.quantity = 1;
          return;
        }

        if (updatedValue <= currentItemQuantity) {
          return;
        }

        state.orderReturnForm.selectedProductToReturn.quantity = currentItemQuantity;

        return;
      }

      if (!increase) {

        if (state.orderReturnForm.selectedProductToReturn.quantity > 1) {
          state.orderReturnForm.selectedProductToReturn.quantity--;
        }

        return;
      }

      if (state.orderReturnForm.selectedProductToReturn.quantity < currentItemQuantity) {
        state.orderReturnForm.selectedProductToReturn.quantity++;
      }

    };


    const handleModalClose = () => state.showModal = !state.showModal;

    const handleDataSubmit = async () => {

      state.orderReturnForm.isSubmitting = true;

      const data = {
        order_id: props.order.id,
        subject: state.orderReturnForm.subject,
        description: state.orderReturnForm.description,
      };

      if (state.isReturningProduct) {
        data.product = state.orderReturnForm.selectedProductToReturn.item;
        data.quantity = state.orderReturnForm.selectedProductToReturn.quantity;
        data.return_type = state.orderReturnForm.selectedProductToReturn.type;
      }

      try {

        const response = await submitOrderIssue(data);
        await Toast.success(response.data.message || 'we have received your issue. we will inform you soon');
        state.orderReturnForm.isSubmitting = false;
        state.showModal = false;

      } catch (e) {

        ErrorHelper.mapServerError(e, (error, extractor) => {
          state.errors.subject = extractor(error.subject);
          state.errors.description = extractor(error.description);
        });

        await Toast.error(e.message || 'something went wrong. please try again');
        state.orderReturnForm.isSubmitting = false;
      }

    };

    const watcherOption = { deep: true, immediate: true };

    const returnProductSubjectRender = (order) => {
      if (order.status !== 'delivered') {
        return;
      }

      state.orderReturnSubject.splice(-1, 0,{
        text: 'Return item',
        value: 'return-item'
      });
    };

    watch(() => props.order, (nv) => returnProductSubjectRender(nv));

    watch(() => props.value, (nv) => {

      if (nv) resetForm();

      state.showModal = nv;

    }, watcherOption);
    watch(() => state.showModal, (nv) => emit('input', nv), watcherOption);

    watch(() => state.orderReturnForm.description, (nv) => {
      if (nv) {
        state.errors.description = '';
      }
    }, watcherOption);

    watch(() => props.products, (nv) => state.productList = nv, watcherOption);

    return {
      state,
      handleModalClose,
      handleDataSubmit,
      updateReturnItemQty
    };

  },

});
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.plus-minus-icon .v-icon {
  background: #ddd;
  padding: 2.5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-right: 6px;
}

</style>
